import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';

interface SectionProps {
  header: string;
  content: React.ReactNode;
}

const Section = ({ header, content }: SectionProps) =>
  (
    <Accordion allowToggle w={'100%'}>
      <AccordionItem>
        <AccordionButton px={0}>
          <Box flex="1" textAlign="left">
            <Heading size="md">{header}</Heading>
          </Box>
          <AccordionIcon/>
        </AccordionButton>
        <AccordionPanel pb={4}>
          {content}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );

export default Section;
