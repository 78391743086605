import React, { useEffect } from 'react';
import { Box, Heading, Spinner } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../hooks/useStore';
import StatsCard from '../../components/cards/StatsCard';
import StatsUsersTable from '../../components/table/StatsUsersTable';

const DashboardHomeScreen = () => {
  const {
    statisticsStore: { fetchStatistics, statistics, loading },
  } = useStore();

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <Box>
      <Heading size="lg" mb={4}>Dashboard</Heading>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl"/>
        </Box>
      ) : (
        <>
          <Box display={'flex'} gap={8} pb={50}>
            <StatsCard
              title="Total Registered Users"
              number={statistics.numberOfUsers}
              subtitle={`${statistics.newSignUpsPast24Hours.length} new sign ups in the past 24 hours`}
            />
            <StatsCard
              title="Total Unique Bidders"
              number={statistics.numberOfUniqueBidders}
              subtitle={`${statistics.newBiddersPast24Hours.length} new bidders in the past 24 hours`}
            />
          </Box>
          <Box display={'flex'} gap={20}>
            {statistics.newSignUpsPast24Hours.length ?
              <StatsUsersTable tableData={statistics.newSignUpsPast24Hours} title="New Sign Ups Past 24 Hours"/> : null}
            {statistics.newBiddersPast24Hours.length ?
              <StatsUsersTable tableData={statistics.newBiddersPast24Hours} title="New Bidders Past 24 Hours"/> : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default observer(DashboardHomeScreen);
