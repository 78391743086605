import { endpoints } from '../constants/endpoints';
import { UserDto, UserQueryDto } from '../constants/types';

import { BaseApi } from './index';

export class UserApi extends BaseApi<UserDto> {
  constructor() {
    super(endpoints.users.base);
  }

  async getUsers(query: Partial<UserQueryDto>) {
    return this.fetch(endpoints.users.base, { params: query });
  }

  async deleteUser(id: string) {
    return this.delete(endpoints.users.byId(id));
  }

  async getUser(id: string) {
    return this.getById(id);
  }

  async enableDisableSeller(id: string, enabled: boolean) {
    return this.patch(endpoints.users.enableDisable(id), { enabled });
  }

  async approveSeller(id: string) {
    return this.patch(endpoints.users.approve(id), {});
  }

  async rejectSeller(id: string) {
    return this.patch(endpoints.users.reject(id), {});
  }

  async updateSellerFee(id: string, sellerFee: number) {
    return this.patch(endpoints.users.sellerFee(id), { sellerFee });
  }
}
