import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { ListingType } from '../../constants/enums';
import { Colors } from '../../theme/colors';

type PillProps = {
  type: ListingType;
}

const ListingTypePill = ({ type }: PillProps) => {
  const isAuction = type === ListingType.AUCTION;
  const textColorAuction = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const textColorBuyNow = useColorModeValue(Colors.light.secondary, Colors.dark.secondary);

  return (
    <Box
      display="inline-block"
      px={'20px'}
      py={'4px'}
      bg={'transparent'}
      color="white"
      borderRadius="full"
      border={`1px solid ${isAuction ? textColorAuction : textColorBuyNow}`}
    >
      <Text color={isAuction ? textColorAuction : textColorBuyNow}>
        {isAuction ? 'Auction' : 'Buy Now'}
      </Text>
    </Box>
  );
};

export default ListingTypePill;
