import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Avatar,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Box,
  useColorModeValue
} from '@chakra-ui/react';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';

import { User } from '../../constants/types';
import { Colors } from '../../theme/colors';

type StatsUsersTableProps = {
  title: string;
  tableData: User[];
};

const StatsUsersTable = ({ title, tableData }: StatsUsersTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const minHeightForRow = 80;
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const minHeight = minHeightForRow * (itemsPerPage + 1); // +1 for the header row
  const bgColor = useColorModeValue(Colors.light.gray, Colors.dark.gray);

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const currentTableData = tableData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <TableContainer height={minHeight} display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>{title}</Text>
      <Table variant={'striped'} backgroundColor={bgColor} borderRadius={10} colorScheme={'green'} padding={20}>
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Date Joined</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentTableData.map((data) => {
            const parsedDate = new Date(data.createdAt || '').toLocaleDateString();

            return (
              <Tr key={data.userName}>
                <Td>
                  <HStack>
                    <Avatar size="md" src={data.avatar} key={data.userName} name={data.userName}/>
                    <Text>{data.userName || '-'}</Text>
                  </HStack>
                </Td>
                <Td>{parsedDate}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Box display="flex" alignItems="center" mt={4} position={'absolute'} bottom={0}>
        <Button
          onClick={() => handleClick(currentPage - 1)}
          isDisabled={currentPage === 1}
          mr={2}
        >
          <BiLeftArrow/>
        </Button>
        {[...Array(totalPages)].map((_, i) => (
          <Button
            key={i}
            onClick={() => handleClick(i + 1)}
            variant={i + 1 === currentPage ? 'solid' : 'outline'}
            mx={1}
          >
            {i + 1}
          </Button>
        ))}
        <Button
          onClick={() => handleClick(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          ml={2}
        >
          <BiRightArrow/>
        </Button>
      </Box>
    </TableContainer>
  );
};

export default observer(StatsUsersTable);
