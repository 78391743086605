export const Routes = {
  Home: '/',
  Login: '/auth/login',
  Dashboard: '/dashboard',
  DashboardHome: '/dashboard/home',
  DashboardUsers: '/dashboard/users',
  DashboardListings: '/dashboard/listings',
  DashboardListingsActive: '/dashboard/listings/active',
  DashboardListingsSold: '/dashboard/listings/sold',
  DashboardCustomerSupport: '/dashboard/customer-support',
  DashboardSettings: '/dashboard/settings',
};
