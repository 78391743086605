import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  IconButton,
  Flex,
  TableContainer,
  Box,
  Text, Spinner
} from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight, FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';

import { ListingQueryDto, TableHeader } from '../../constants/types';
import { OrderType } from '../../constants/enums';

interface TableProps {
  headers: TableHeader[];
  children: React.ReactNode;
  rowsPerPage: number;
  totalRows: number;
  fetchDataTable: (query: Partial<ListingQueryDto>) => Promise<void>;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  loading?: boolean;
}

const CustomTable = ({
  headers,
  children,
  rowsPerPage,
  totalRows,
  fetchDataTable,
  currentPage,
  setCurrentPage,
  loading
  }: TableProps) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const [sortBy, setSortBy] = useState<undefined | string>(undefined);
  const [order, setOrder] = useState<undefined | string>(undefined);

  const fetchPageData = async (page: number) => {
    await fetchDataTable({ page, limit: rowsPerPage, sortBy, order });
  };

  const handlePreviousPage = async () => {
    const newPage = Math.max(currentPage - 1, 1);
    await fetchPageData(newPage);
    setCurrentPage(newPage);
  };

  const handleNextPage = async () => {
    const newPage = Math.min(currentPage + 1, totalPages);
    await fetchPageData(newPage);
    setCurrentPage(newPage);
  };

  const startRow = (currentPage - 1) * rowsPerPage;
  const endRow = startRow + rowsPerPage;

  const minHeightForRow = 75;
  const minHeight = minHeightForRow * (rowsPerPage + 1); // +1 for the header row

  const handleSort = (header: TableHeader) => {
    if (sortBy === header.sortKey) {
      if (order === OrderType.DESC) {
        setOrder('');
        setSortBy('');
      }

      setOrder(order === OrderType.ASC ? OrderType.DESC : '');

    } else {
      setSortBy(header.sortKey);
      setOrder(OrderType.ASC);
    }
  };

  const renderSortIcon = (header: TableHeader) => {
    if (!header.sortable) {
      return null;
    }

    if (sortBy === header.sortKey) {
      return order === OrderType.ASC ? <FaSortUp/> : <FaSortDown/>;
    } else {
      return <FaSort/>;
    }
  };

  useEffect(() => {
    if (sortBy !== undefined && order !== undefined) {
      (async () => await fetchDataTable({ page: 1, limit: rowsPerPage, sortBy, order }))();
    }
  }, [sortBy, order]);

  return (
    <>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'}>
          <Spinner size={'xl'} m={10}/>
        </Box>
      ) : (
        <Box position={'relative'} height={minHeight}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  {headers.map((header, index) => (
                    <Th key={index} onClick={() => header.sortable && handleSort(header)}>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Text width={'fit-content'}>{header.displayName}</Text> {renderSortIcon(header)}
                      </Box>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>{React.Children.toArray(children).slice(startRow, endRow)}</Tbody>
            </Table>
          </TableContainer>
          <Flex position={'absolute'} bottom={0}>
            <IconButton
              aria-label="Previous Page"
              icon={<FaChevronLeft/>}
              onClick={handlePreviousPage}
              isDisabled={currentPage === 1}
            />
            <Button mx={2}>{currentPage}</Button>
            <IconButton
              aria-label="Next Page"
              icon={<FaChevronRight/>}
              onClick={handleNextPage}
              isDisabled={currentPage === totalPages}
            />
          </Flex>
        </Box>)}
    </>
  );
};

export default observer(CustomTable);
