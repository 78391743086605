import { endpoints } from '../constants/endpoints';
import { StatisticDto } from '../constants/types';

import { BaseApi } from './index';

export class StatisticsApi extends BaseApi<StatisticDto> {
  constructor() {
    super(endpoints.statistics.base);
  }

  async getStatistics() {
    return this.fetch();
  }
}
