import React, { useEffect, useState } from 'react';
import { Box, Td, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../hooks/useStore';
import CustomTable from '../../../components/table/CustomTable';
import { soldListingHeaders } from '../../../constants/tableHeaders';
import SearchBar from '../../../components/common/SearchBar';
import useDebounce from '../../../hooks/useDebounce';
import DetailDrawer from '../../../components/drawer/DetailDrawer';
import { SoldListing } from '../../../constants/types';
import ListingTypePill from '../../../components/common/ListingTypePill';
import { parseDate } from '../../../utils/date';
import ListingDetails from '../../../components/drawer/ListingDetails';
import DeleteMenu from '../../../components/common/DeleteMenu';

const SoldListingsScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    listingStore: {
      soldListings,
      fetchSoldListings,
      totalSoldListings,
      soldListingsSearch,
      setSoldListingsSearch,
      setDisplayedSoldListing,
      deleteOrder,
      loading
    },
  } = useStore();

  const handleRowClick = (row: SoldListing) => {
    setDisplayedSoldListing(row);
    onOpen();
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteOrder(id);
      onClose();
      toast({
        title: 'Success',
        description: 'Order deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await fetchSoldListings({ page: 1, limit: 10 });
      setCurrentPage(1);
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to delete order',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const debouncedSearch = useDebounce(soldListingsSearch, 500);

  useEffect(() => {
    setCurrentPage(1);
    (async () => await fetchSoldListings({ page: 1, limit: 10 }))();
  }, [debouncedSearch]);

  return (
    <Box>
      <SearchBar
        search={soldListingsSearch}
        setSearch={setSoldListingsSearch}
        placeholder={'Search for sold listing'}
      />
      <CustomTable
        headers={soldListingHeaders}
        rowsPerPage={10}
        totalRows={totalSoldListings}
        fetchDataTable={fetchSoldListings}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {(soldListings || []).map((order: any) => {
          const parsedDate = parseDate(order.createdAt);

          return (
            <Tr key={order.id} onClick={() => handleRowClick(order)}>
              <Td>{order.listing?.title}</Td>
              <Td><ListingTypePill type={order.listing?.listingType}/></Td>
              <Td>{order.price}</Td>
              <Td>{order.shippingPrice}</Td>
              <Td>{order.user?.userName}</Td>
              <Td>{order.seller?.userName}</Td>
              <Td>{parsedDate.date}<br/>{parsedDate.time}</Td>
              <Td>{order.status}</Td>
              <Td>{order.trackingCode}</Td>
              <Td>
                <DeleteMenu
                  title={'Delete order?'}
                  subtitle={'Are you sure you want to delete this order? This action cannot be undone.'}
                  cancelActionLabel={'No, keep it'}
                  confirmActionLabel={'Delete order'}
                  onDelete={() => handleDelete(order.id)}/>
              </Td>
            </Tr>
          )
        })}
      </CustomTable>
      <DetailDrawer isOpen={isOpen} onClose={onClose}>
        <ListingDetails/>
      </DetailDrawer>
    </Box>
  );
};

export default observer(SoldListingsScreen);
