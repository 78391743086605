import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text, Stack, Icon, Flex, Button, Spinner, useColorModeValue } from '@chakra-ui/react';
import { FaInfoCircle, FaClock, FaInstagram, FaTiktok, FaLink } from 'react-icons/fa';

import { useStore } from '../../../hooks/useStore';
import { Colors } from '../../../theme/colors';

const ReviewUserRequest = () => {
  const {
    userStore: { displayedUser: { pendingRequest }, approveSeller, rejectSeller },
  } = useStore();
  const [loading, setLoading] = useState(false);
  const bgColor = useColorModeValue(Colors.light.background, Colors.dark.background);

  const {
    description = '',
    createdAt = '',
    instagramLink,
    tikTokLink,
    otherProfileLink,
  } = pendingRequest || {};

  const handleApprove = async () => {
    try {
      setLoading(true);
      await approveSeller();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      await rejectSeller();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        borderRadius="md"
        bg={bgColor}
        maxWidth="600px"
        mx="auto"
        w={'70%'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      bg={bgColor}
      maxWidth="600px"
      mx="auto"
      w={'70%'}
    >
      <Text fontSize="xl" fontWeight="bold" mb={3}>
        Pending Request
      </Text>
      <Stack spacing={3} mb={4}>
        <Box>
          <Flex align="center">
            <Icon as={FaInfoCircle} mr={2} />
            <Text fontWeight="bold">Description:</Text>
          </Flex>
          <Text>{description}</Text>
        </Box>
        <Box>
          <Flex align="center">
            <Icon as={FaClock} mr={2} />
            <Text fontWeight="bold">Created At:</Text>
          </Flex>
          <Text>{new Date(createdAt).toLocaleString()}</Text>
        </Box>
        {instagramLink && (
          <Box>
            <Flex align="center">
              <Icon as={FaInstagram} mr={2} />
              <Text fontWeight="bold">Instagram:</Text>
            </Flex>
            <Text>{instagramLink}</Text>
          </Box>
        )}
        {tikTokLink && (
          <Box>
            <Flex align="center">
              <Icon as={FaTiktok} mr={2} />
              <Text fontWeight="bold">TikTok:</Text>
            </Flex>
            <Text>{tikTokLink}</Text>
          </Box>
        )}
        {otherProfileLink && (
          <Box>
            <Flex align="center">
              <Icon as={FaLink} mr={2} />
              <Text fontWeight="bold">Other Profile:</Text>
            </Flex>
            <Text>{otherProfileLink}</Text>
          </Box>
        )}
      </Stack>
      <Flex justify="space-between" gap={10} flexWrap={'wrap'}>
        <Button colorScheme="red" onClick={handleReject} flex={1}>
          Reject
        </Button>
        <Button colorScheme="green" onClick={handleApprove} flex={1}>
          Approve
        </Button>
      </Flex>
    </Box>
  );
}

export default observer(ReviewUserRequest);
