import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom';

import LoginScreen from '../screens/auth/LoginScreen';
import DashboardHomeScreen from '../screens/dashboard/DashboardHomeScreen';
import DashboardLayout from '../components/common/DashboardLayout';
import UsersScreen from '../screens/dashboard/UsersScreen';
import ActiveListingsScreen from '../screens/dashboard/listings/ActiveListingsScreen';
import SoldListingsScreen from '../screens/dashboard/listings/SoldListingsScreen';

import { Routes } from './routes';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={Routes.Home} element={<Navigate to={Routes.DashboardHome} />} />
      <Route path={Routes.Login} element={<LoginScreen />} />
      <Route path={Routes.Dashboard} element={<DashboardLayout />}>
        <Route path={Routes.DashboardHome} element={<DashboardHomeScreen />} />
        <Route path={Routes.DashboardUsers} element={<UsersScreen />} />
        <Route path={Routes.DashboardListings} element={<div>Listings</div>} />
        <Route path={Routes.DashboardListingsActive} element={<ActiveListingsScreen />} />
        <Route path={Routes.DashboardListingsSold} element={<SoldListingsScreen />} />
        <Route path={Routes.DashboardCustomerSupport} element={<div>Customer Support</div>} />
        <Route path={Routes.DashboardSettings} element={<div>Settings</div>} />
      </Route>
    </>
  )
);
