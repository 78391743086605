import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { Colors } from '../../theme/colors';

type InfoDisplayProps = {
  title: string;
  value: string | number | React.ReactNode;
}
const InfoDisplay = ({ title, value }: InfoDisplayProps) => {
  const textColor = useColorModeValue(Colors.light.fadedText, Colors.dark.fadedText);

  return (
    <Box display={'flex'} flex={1} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
      <Text flex={1}>{title}</Text>
      <Text color={textColor} flex={1}>{value}</Text>
    </Box>
  );
};

export default InfoDisplay;
