import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';

import { firebase } from '../services/firebase';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(async (config: any) => {
  const auth = getAuth(firebase);
  const user = auth.currentUser;

  if (user) {
    const token = await getIdToken(user, true);
    config.headers.Authorization = `Bearer ${token}`;
    localStorage.setItem('accessToken', token);

  } else {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default instance;
