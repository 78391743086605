import React, { useEffect, useState } from 'react';
import { Box, Td, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../hooks/useStore';
import CustomTable from '../../../components/table/CustomTable';
import { activeListingHeaders } from '../../../constants/tableHeaders';
import { ListingType } from '../../../constants/enums';
import SearchBar from '../../../components/common/SearchBar';
import useDebounce from '../../../hooks/useDebounce';
import DetailDrawer from '../../../components/drawer/DetailDrawer';
import { ActiveListing } from '../../../constants/types';
import ActiveListingDetails from '../../../components/drawer/ActiveListingDetails';
import ListingTypePill from '../../../components/common/ListingTypePill';
import { parseDate } from '../../../utils/date';
import DeleteMenu from '../../../components/common/DeleteMenu';

const ActiveListingsScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    listingStore: {
      activeListings,
      fetchActiveListings,
      totalActiveListings,
      activeListingsSearch,
      setActiveListingsSearch,
      setDisplayedActiveListing,
      loading,
      deleteListing
    },
  } = useStore();
  const toast = useToast();

  const handleRowClick = (row: ActiveListing) => {
    setDisplayedActiveListing(row);
    onOpen();
  };

  const debouncedSearch = useDebounce(activeListingsSearch, 500);

  useEffect(() => {
    setCurrentPage(1);
    (async () => await fetchActiveListings({ page: 1, limit: 10 }))();
  }, [debouncedSearch]);

  const handleDelete = async (id: string) => {
    try {
      await deleteListing(id);
      onClose();
      toast({
        title: 'Success',
        description: 'Listing deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await fetchActiveListings({ page: 1, limit: 10 });
      setCurrentPage(1);
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to delete listing',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <SearchBar
        search={activeListingsSearch}
        setSearch={setActiveListingsSearch}
        placeholder={'Search for listing'}
      />
      <CustomTable
        headers={activeListingHeaders}
        rowsPerPage={10}
        totalRows={totalActiveListings}
        fetchDataTable={fetchActiveListings}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {(activeListings || []).map((listing) => {
          const parsedDate = parseDate(listing.createdAt);

          return (
            <Tr key={listing.id} onClick={() => handleRowClick(listing)}>
              <Td>{listing.title}</Td>
              <Td>{listing.description.length > 50 ? listing.description.slice(0, 50) + '...' : listing.description}</Td>
              <Td>{parsedDate.date}<br/>{parsedDate.time}</Td>
              <Td>{listing.user?.userName}</Td>
              <Td><ListingTypePill type={listing.listingType}/></Td>
              <Td>{listing.inStock}</Td>
              <Td>{listing.listingType === ListingType.DEFAULT ? listing.startingBid : 'N/A'}</Td>
              <Td>{listing.listingType === ListingType.AUCTION ? listing.startingBid : 'N/A'}</Td>
              <Td>{listing.listingType === ListingType.AUCTION ? listing.bid : 'N/A'}</Td>
              <Td>
                <DeleteMenu
                  title={'Delete listing?'}
                  subtitle={'Are you sure you want to delete this listing? This action cannot be undone.'}
                  cancelActionLabel={'No, keep it'}
                  confirmActionLabel={'Delete listing'}
                  onDelete={() => handleDelete(listing.id)}/>
              </Td>
            </Tr>
          )
        })}
      </CustomTable>
      <DetailDrawer isOpen={isOpen} onClose={onClose}>
        <ActiveListingDetails/>
      </DetailDrawer>
    </Box>
  );
};

export default observer(ActiveListingsScreen);
