import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { Colors } from '../../theme/colors';

type StatsCardProps = {
  number: number;
  title: string;
  subtitle: string;
};

const StatsCard = ({ number, title, subtitle }: StatsCardProps) => {
  const cardBackground = useColorModeValue(Colors.light.gray3, Colors.dark.gray3);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  return (
    <Box
      p={'25px'}
      bg={cardBackground}
      borderRadius="md"
      boxShadow="md"
      color={textColor}
      w={'fit-content'}
    >
      <Text fontSize="4xl" fontWeight="bold">
        {number}
      </Text>
      <Text fontSize="md" fontWeight="medium">
        {title}
      </Text>
      <Text fontSize="sm" color={primaryColor}>
        {subtitle}
      </Text>
    </Box>
  );
};

export default StatsCard;
