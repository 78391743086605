import React from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton, useColorModeValue, Box,
} from '@chakra-ui/react';
import { FaSearch, FaTimes } from 'react-icons/fa';

import { Colors } from '../../theme/colors';

type SearchBarProps = {
  placeholder?: string;
  search: string;
  setSearch: (search: string) => void;
}

const SearchBar = ({ placeholder, search, setSearch }: SearchBarProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearch('');
  };

  return (
    <Box
      bg={useColorModeValue(Colors.light.gray, Colors.dark.gray)}
      p={'10px 14px'}
      borderRadius={10}
    >
      <InputGroup
        bg={useColorModeValue(Colors.light.background, Colors.dark.background)}
        borderRadius={10}
        w={'40%'}
      >
        <InputLeftElement pointerEvents="none">
          <FaSearch color={useColorModeValue(Colors.light.gray1, Colors.dark.gray1)}/>
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          value={search}
          onChange={handleInputChange}
        />
        {search && (
          <InputRightElement>
            <IconButton
              aria-label="Clear search"
              icon={<FaTimes/>}
              size="sm"
              onClick={clearSearch}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};

export default SearchBar;
