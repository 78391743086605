export const endpoints = {
  users: {
    base: '/admin/users',
    byId: (id: string) => `/admin/users/${id}`,
    enableDisable: (id: string) => `/admin/users/${id}/enable-disable-seller`,
    approve: (id: string) => `/admin/users/${id}/approve`,
    reject: (id: string) => `/admin/users/${id}/reject`,
    sellerFee: (id: string) => `/admin/users/${id}/seller-fee`,
  },
  listings: {
    base: '/admin/listings',
    active: '/admin/listings/active',
    sold: '/admin/listings/sold',
    soldById: (id: string) => `/admin/listings/sold/${id}`,
    activeById: (id: string) => `/admin/listings/active/${id}`,
    orderById: (id: string) => `/admin/listings/order/${id}`,
  },
  statistics: {
    base: '/admin/statistics',
  },
};
