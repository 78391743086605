import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button, useColorModeValue,
} from '@chakra-ui/react';

import { Colors } from '../../theme/colors';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  cancelActionLabel: string;
  confirmActionLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  cancelActionLabel,
  confirmActionLabel,
  onCancel,
  onConfirm,
  }: ConfirmationModalProps) => {
  const bgColor = useColorModeValue(Colors.light.background, Colors.dark.background);

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay/>
      <ModalContent bg={bgColor}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          {subtitle}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" onClick={handleConfirm} variant={'solid'}>
            {confirmActionLabel}
          </Button>
          <Button onClick={handleCancel} variant={'outline'} ml={3}>
            {cancelActionLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
