import { AuthStore } from './AuthStore';
import { ListingStore } from './ListingStore';
import { UserStore } from './UserStore';
import { StatisticsStore } from './StatisticsStore';

export default class RootStore {
  authStore: AuthStore;
  listingStore: ListingStore;
  userStore: UserStore;
  statisticsStore: StatisticsStore;

  constructor() {
    this.authStore = new AuthStore();
    this.listingStore = new ListingStore();
    this.userStore = new UserStore();
    this.statisticsStore = new StatisticsStore();
  }
}
