import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { router } from './router/router';
import theme from './theme';
import { auth } from './services/firebase';

const App = () => {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          localStorage.setItem('accessToken', token);
        });
      } else {
        localStorage.removeItem('accessToken');
      }
    });

    const refreshToken = () => {
      const user = auth.currentUser;
      if (user) {
        user.getIdToken(true).then((token) => {
          localStorage.setItem('accessToken', token);
        }).catch((error) => {
          console.error('Error refreshing token:', error);
        });
      }
    };

    // refresh token every 59 minutes
    const interval = setInterval(refreshToken, 59 * 60 * 1000);

    return () => {
      unsubscribe();
      clearInterval(interval);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;
