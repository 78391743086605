import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button, useColorModeValue,
} from '@chakra-ui/react';

import { Colors } from '../../theme/colors';

interface DetailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const DetailDrawer = ({ isOpen, onClose, children }: DetailDrawerProps) =>
  (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay/>
      <DrawerContent bg={useColorModeValue(Colors.light.background, Colors.dark.background)} minW={'35%'}>
        <DrawerCloseButton/>
        <DrawerHeader>Details</DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        <DrawerFooter>
          <Button variant="outline" onClick={onClose}>Close</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );

export default DetailDrawer;
