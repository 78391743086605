import { makeAutoObservable } from 'mobx';

import { ListingApi } from '../api/ListingApi';
import { ActiveListing, ListingQueryDto, SoldListing } from '../constants/types';
import { ListingType } from '../constants/enums';

export class ListingStore {
  api: ListingApi;
  activeListings: ActiveListing[] = [];
  soldListings: SoldListing[] = [];
  totalActiveListings: number = 0;
  totalSoldListings: number = 0;
  displayedActiveListing: ActiveListing = {
    id: '',
    title: '',
    description: '',
    listingType: ListingType.DEFAULT,
    video: '',
    createdAt: '',
    inStock: 0,
    user: { id: '', userName: '', email: '' },
    bid: 0,
    startingBid: 0,
    massUnit: 'oz',
    weight: 0,
    width: 0,
    height: 0,
    length: 0
  };
  displayedSoldListing: SoldListing = {
    id: '',
    listing: { id: '', title: '', description: '', listingType: ListingType.DEFAULT, video: '', createdAt: '' },
    seller: { id: '', userName: '', email: '' },
    user: { id: '', userName: '', email: '' },
    createdAt: '',
    price: 0,
    shippingPrice: 0,
    status: '',
    trackingCode: ''
  };
  soldListingsSearch: string = '';
  activeListingsSearch: string = '';
  loading: boolean = false;

  constructor() {
    this.api = new ListingApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setActiveListings(listings: ActiveListing[]) {
    this.activeListings = listings;
  }

  setDisplayedActiveListing(listing: ActiveListing) {
    this.displayedActiveListing = listing;
  }

  setDisplayedSoldListing(listing: SoldListing) {
    this.displayedSoldListing = listing;
  }

  setSoldListings(listings: SoldListing[]) {
    this.soldListings = listings;
  }

  setActiveListingsSearch(value: string) {
    this.activeListingsSearch = value;
  }

  setSoldListingsSearch(value: string) {
    this.soldListingsSearch = value;
  }

  setTotalActiveListings(value: number) {
    this.totalActiveListings = value;
  }

  setTotalSoldListings(value: number) {
    this.totalSoldListings = value;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  async fetchActiveListings(query?: Partial<ListingQueryDto>) {
    query = {
      ...(this.activeListingsSearch && { search: this.activeListingsSearch }),
      ...(query || {}),
    }

    this.setLoading(true)
    const res = await this.api.getActiveListings(query);
    this.setTotalActiveListings(res.count);

    if (query?.page === 1) {
      this.setActiveListings(res.items);
    } else {
      this.setActiveListings([...this.activeListings, ...res.items]);
    }
    this.setLoading(false)
  }

  async fetchSoldListings(query?: Partial<ListingQueryDto>) {
    query = {
      ...(this.soldListingsSearch && { search: this.soldListingsSearch }),
      ...(query || {}),
    }
    this.setLoading(true)

    const res = await this.api.getSoldListings(query);
    this.setTotalSoldListings(res.count);

    if (query?.page === 1) {
      this.setSoldListings(res.items);
    } else {
      this.setSoldListings([...this.soldListings, ...res.items]);
    }
    this.setLoading(false)
  }

  async deleteOrder(id: string) {
    await this.api.deleteOrder(id);
  }

  async deleteListing(id: string) {
    await this.api.deleteListing(id);
  }

  async updateListingInStore(listingId: string) {
    const updatedListing = await this.api.getActiveListingById(listingId);
    this.setDisplayedActiveListing(updatedListing);
    const index = this.activeListings.findIndex((l) => l.id === listingId);

    if (index !== -1) {
      this.setActiveListings([
          ...this.activeListings.slice(0, index),
          updatedListing,
          ...this.activeListings.slice(index + 1)
        ]
      )
    }
  }

  async updateListing(listing: ActiveListing) {
    await this.api.updateActiveListing(listing.id, listing);
    await this.updateListingInStore(listing.id);
  }
}
