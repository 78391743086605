import React from 'react';
import { AspectRatio, Box, Text, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../hooks/useStore';
import ListingTypePill from '../common/ListingTypePill';
import { parseDate } from '../../utils/date';

import InfoDisplay from './InfoDisplay';
import Section from './Section';

const ListingDetails = () => {
  const {
    listingStore: { displayedSoldListing },
  } = useStore();
  const parsedDate = parseDate(displayedSoldListing.createdAt);

  // replace http with https to avoid mixed content error
  const videoUrl = displayedSoldListing?.listing?.video.replace('http://', 'https://');

  return (
    <Box p={4}>
      <Box display="flex" gap={5} mb={5}>
        <AspectRatio ratio={1} flex={2}>
          <iframe title="video" src={videoUrl} allowFullScreen/>
        </AspectRatio>
        <Text flex={1}>
          {displayedSoldListing?.listing?.title}
        </Text>
      </Box>
      <VStack spacing={4} align="start">
        <Section
          header="Shipping Information"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Order Tracking No:'} value={displayedSoldListing?.trackingCode}/>
              <InfoDisplay title={'Order Status:'} value={displayedSoldListing?.status}/>
              <InfoDisplay title={'Shipping Price:'} value={'$' + displayedSoldListing?.shippingPrice}/>
              <InfoDisplay title={'Order Price:'} value={'$' + displayedSoldListing?.price}/>
              <InfoDisplay title={'Date Sold:'} value={parsedDate.date + ' ' + parsedDate.time}/>
            </VStack>
          }
        />
        <Section
          header="Listing Detail"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Listing Description:'} value={displayedSoldListing?.listing?.description}/>
              <InfoDisplay title={'Listing Type:'}
                           value={<ListingTypePill type={displayedSoldListing.listing?.listingType}/>}/>
            </VStack>
          }
        />
        <Section
          header="Buyer Information"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Buyer username:'} value={displayedSoldListing?.user?.userName}/>
              <InfoDisplay title={'E-mail:'} value={displayedSoldListing?.user?.email}/>
            </VStack>
          }
        />
        <Section
          header="Seller Information"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Seller username:'} value={displayedSoldListing?.seller?.userName}/>
              <InfoDisplay title={'E-mail:'} value={displayedSoldListing?.seller?.email}/>
            </VStack>
          }
        />
      </VStack>
    </Box>
  );
};

export default observer(ListingDetails);
