import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { Colors } from './colors';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode(Colors.light.background, Colors.dark.background)(props),
        color: mode(Colors.light.text, Colors.dark.text)(props),
      },
    }),
  },
  colors: {
    primary: {
      100: mode(Colors.light.primary, Colors.dark.primary),
    },
  },
});

export default theme;
