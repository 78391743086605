import { AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from '../config/axios';

export class BaseApi<T> {
  protected endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async get(endpoint: string, params?: string) {
    const response: AxiosResponse<T> = await axios.get(endpoint, {
      params: params,
    });
    return response.data;
  }

  async create(data: Partial<T>, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await axios.post(
      this.endpoint,
      data,
      config
    );
    return response.data;
  }

  async post(
    endpoint: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await axios.post(endpoint, data, config);
    return response.data;
  }

  async getById(id: number | string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await axios.get(
      `${this.endpoint}/${id}`,
      config
    );
    return response.data;
  }

  async fetch(
    endpoint?: string,
    config?: AxiosRequestConfig
  ): Promise<{ items: T[]; count: number }> {
    const endpointToUse = endpoint || this.endpoint;
    const response: AxiosResponse<{ items: T[]; count: number }> =
      await axios.get(endpointToUse, config);
    return response.data;
  }

  async update(
    id: number,
    data: Partial<T>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await axios.patch(
      `${this.endpoint}/${id}`,
      data,
      config
    );
    return response.data;
  }

  async patch(
    endpoint: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await axios.patch(
      endpoint,
      data,
      config
    );
    return response.data;
  }

  async delete(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await axios.delete(
      endpoint,
      config
    );
    return response.data;
  }
}
