import { makeAutoObservable } from 'mobx';

import { StatisticsApi } from '../api/StatisticsApi';
import { StatisticDto } from '../constants/types';

export class StatisticsStore {
  api: StatisticsApi;
  loading: boolean = false;
  statistics: StatisticDto = {
    numberOfUsers: 0,
    numberOfUniqueBidders: 0,
    newSignUpsPast24Hours: [],
    newBiddersPast24Hours: [],
  };

  constructor() {
    this.api = new StatisticsApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setStatistics(statistics: StatisticDto) {
    this.statistics = statistics;
  }

  async fetchStatistics() {
    this.setLoading(true);
    try {
      const response = await this.api.getStatistics() as any;
      this.setStatistics(response);
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoading(false);
    }
  }
}
