import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { Box } from '@chakra-ui/react';

import SideNavigator from '../navigation/SideNavbar';
import { useStore } from '../../hooks/useStore';
import { Routes } from '../../router/routes';
import { auth } from '../../services/firebase';

const DashboardLayout = () => {
  const {
    authStore: { setUser },
  } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate(Routes.Login);
        setUser(undefined);
      }
    });

    return () => unsubscribe();
  }, [navigate, setUser]);

  return (
    <Box display="flex">
      <SideNavigator />
      <Box ml="250px" p={4} flex="1">
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
